<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
      v-if="!fetchinData"
    />

    <v-row v-if="!fetchinData">
      <v-col cols="12" md="8">
        <v-card tile>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>{{ $t("phrases.Form") }}</v-toolbar-title>
            <v-spacer />
            <SelectFieldToAdd
              :perm="auth.permissions"
              :fields="data.fields"
              @action="addFieldEvent"
              :key="componentKey"
            />
          </v-toolbar>

          <v-card-text>
            <draggable
              v-model="data.fields"
              ghost-class="ghost"
              :move="checkMove"
              @start="dragging = true"
              @end="handleDragEnd('category')"
              :handle="disabledHandleByPermission(writePerm)"
              draggable=".is-draggable"
            >
              <template v-if="data.fields.length > 0">
                <template v-for="field in data.fields">
                  <div class="is-draggable" :key="field.uuid">
                    <component
                      class="my-2"
                      :is="field.fieldType + 'Type'"
                      :data="field"
                      :perm="auth.permissions"
                      @action="fieldAction"
                    ></component>
                  </div>
                </template>
              </template>
            </draggable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <AreYouSureDialog
      :title="this.$t('dialogs.areYouSure')"
      :text="this.$t('dialogs.deleteInfo')"
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import DataPatcher from "@/components/ui/DataPatcher";
import draggable from "vuedraggable";
import SelectFieldToAdd from "@/components/plugins/forms/SelectFieldToAdd";
import checkboxType from "@/components/plugins/forms/field-components/checkboxType";
import radioType from "@/components/plugins/forms/field-components/radioType";
import selectType from "@/components/plugins/forms/field-components/selectType";
import textType from "@/components/plugins/forms/field-components/textType";
import textareaType from "@/components/plugins/forms/field-components/textareaType";
export default {
  name: "CategoryView",

  components: {
    PageHeader,
    AreYouSureDialog,
    DataPatcher,
    draggable,
    SelectFieldToAdd,
    checkboxType,
    radioType,
    selectType,
    textType,
    textareaType,
  },

  data() {
    return {
      dragging: false,
      formUuid: this.$route.params.id,
      safeguardingDialog: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      pageTitle: null,
      pageDescription: null,
      fetchinData: false, // should be true when fetching data
      auth: [],
      data: [],
      componentKey: 0,
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      masterPerm: null,
      itemToDelete: null,
      typeToDelete: null,
      patchRoute: `/company/${store.getters["auth/session"].currentCompany.companyUuid}/forms/${this.$route.params.id}`,
      draggedItemUuid: null,
    };
  },

  props: [],

  created: function () {
    this.getData();
  },

  methods: {
    async addField(fieldType) {
      let formData = new FormData();
      formData.append("fieldType", fieldType);

      this.preloader = true;
      let response = await this.post(
        `/company/${this.companyID}/forms/${this.formUuid}/fields`,
        "",
        formData
      );
      this.preloader = false;

      if (response.status === 201) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemAddedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // todo Mutate
        this.data.fields.push(response.data.data[0]);
        this.componentKey += 1;
      }
    },

    async addStandardField(id) {
      let formData = new FormData();
      formData.append("id", id);

      this.preloader = true;
      let response = await this.post(
        `/company/${this.companyID}/forms/${this.formUuid}/fields/standard`,
        "",
        formData
      );
      this.preloader = false;

      if (response.status === 201) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemAddedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.data.fields.push(response.data.data[0]);
        this.componentKey += 1;
      }
    },

    addFieldEvent(value) {
      if (value.action === "addStandardField") {
        this.addStandardField(value.id);
      } else if (value.action === "addField") {
        this.addField(value.value);
      }
    },

    fieldAction(value) {
      if (value.action === "delete") {
        this.safeguardingDialog = true;
        this.itemToDelete = value.uuid;
        this.componentKey += 1;
      } else if (value === "cancel") {
        // make it reload
        this.componentKey += 1;
      }
    },

    async deleteField() {
      this.preloader = true;
      let response = await this.delete(
        `/company/${this.companyID}/forms/${this.data.uuid}/fields/${this.itemToDelete}`
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemDeletedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // find field to remove it from data (mutate)
        this.data.fields.forEach((field, index) => {
          if (field.uuid === this.itemToDelete) {
            this.data.fields.splice(index, 1); // remove field
            this.itemToDelete = null;
            this.componentKey += 1;
          }
        });
      }
    },

    safeguarding(value) {
      this.safeguardingDialog = false;
      if (value) {
        this.deleteField();
      }
    },

    disabledHandleByPermission(value) {
      // .draghandle
      if (value !== "1" && this.masterPerm !== "1") {
        return ".no";
      } else {
        return ".draghandle";
      }
    },

    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.get(
        `company/${this.companyID}/forms/${this.formUuid}`
      );

      if (response.status === 200) {
        this.data = response.data.data[0];
        this.auth = response.data.auth;
        this.writePerm = this.auth.permissions.write;
        this.deletePerm = this.auth.permissions.delete;
        this.readPerm = this.auth.permissions.read;
        this.masterPerm = this.auth.permissions.master;
      }

      this.fetchinData = false;
    },

    emittedTableEvent(obj) {
      if (obj.action === "deleteItem") {
        this.itemToDelete = obj.item;
        this.safeguardingDialog = true;
      }
    },

    mutateData(value) {
      this.data[value.name] = value.value; // mutate
    },

    async dragUpdate(idAndPrio) {
      const url = `/company/${this.companyID}/frontend/page/${this.formUuid}/${this.pageVersion}/field/prio`;

      let response = await this.put(url, JSON.stringify(idAndPrio), "json");

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Die Reihenfolge wurde erfolgreich geändert.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        this.data.fields = response.data.data[0].fields;
      }
    },

    checkMove: function (e) {
      this.onDrag = true;
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */
      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;

      console.log(e.draggedContext.uuid);
      return false; // disable sort
    },

    handleDragEnd: function () {
      /* hier loope ich den mutierten array durch und bilde
      einen neuen array aus uuid und prio.
      Dieses Array schicke ich dann via POST an die API */

      this.dragging = false;

      let idAndPrio = [];
      let newOrderedArray = this.data.fields;

      let prio = 1;

      newOrderedArray.forEach((item) => {
        const set = {
          uuid: item.uuid,
          prio: prio,
        };

        idAndPrio.push(set);
        ++prio;
      });

      this.dragUpdate(idAndPrio);
    },
  },

  computed: {
    //
  },

  mixins: [api, helpers],
};
</script>

<style>
.draghandle {
  cursor: pointer;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.01);
}
</style>
