var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"color":_vm.stateColor(),"flat":"","dense":"","dark":""}},[_c('v-switch',{staticClass:"mx-1 pr-0",attrs:{"color":"white","inset":"","false-value":"0","true-value":"1","hide-details":"","disabled":_vm.disabledByPermission(_vm.perm.write)},on:{"change":function($event){return _vm.$emit('action', {
        action: 'switchOnlineState',
        state: _vm.data.isOnline,
        uuid: _vm.data.uuid,
      })}},model:{value:(_vm.data.isOnline),callback:function ($$v) {_vm.$set(_vm.data, "isOnline", $$v)},expression:"data.isOnline"}}),_c('v-toolbar-title',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("plugins.frontend.Component"))+": "+_vm._s(_vm.name)+" ")]),_c('v-spacer'),(!_vm.edit)?_c('v-btn',{staticClass:"white--text mx-1",attrs:{"fab":"","dark":"","elevation":"0","x-small":"","color":"blue","disabled":_vm.disabledByPermission(_vm.perm.write)},on:{"click":function($event){return _vm.$emit('action', {
        action: 'edit',
        uuid: _vm.data.uuid,
      })}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('v-btn',{staticClass:"white--text mx-1",attrs:{"fab":"","dark":"","elevation":"0","x-small":"","color":"red","disabled":_vm.disabledByPermission(_vm.perm.delete)},on:{"click":function($event){return _vm.$emit('action', {
        action: 'delete',
        uuid: _vm.data.uuid,
      })}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-trash-can ")])],1),(_vm.perm.write)?_c('v-icon',{staticClass:"draghandle"},[_vm._v(" mdi-drag ")]):_c('v-icon',{staticStyle:{"opacity":"0.5"},attrs:{"disabled":""}},[_vm._v(" mdi-drag ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }