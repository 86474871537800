<template>
  <v-card>
    <v-toolbar color="primary" dark dense>checkboxType</v-toolbar>
    <v-card-text> content </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
export default {
  name: "checkboxType",

  components: {},

  data() {
    return {
      formUuid: this.$route.params.id,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
    };
  },

  props: {
    data: { type: Object, required: true },
  },

  created: function () {},

  methods: {
    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },

  computed: {},

  mixins: [api, helpers],
};
</script>
