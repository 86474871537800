<template>
  <v-row v-if="data" no-gutters>
    <v-col cols="auto">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="green" dark v-bind="attrs" v-on="on" class="mx-2">
            <v-icon left> mdi-plus </v-icon>
            {{ $t("plugins.forms.AddField") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="
              $emit('action', {
                action: 'addField',
                value: item.value,
              })
            "
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="auto">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="orange" dark v-bind="attrs" v-on="on" class="mx-2">
            <v-icon left> mdi-plus </v-icon>
            {{ $t("plugins.forms.AddStandardField") }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in selectableItems"
            :key="index"
            :disabled="item.disabled"
            @click="
              $emit('action', {
                action: 'addStandardField',
                id: item.id,
              })
            "
          >
            <v-list-item-title>{{
              $t("plugins.forms.fields." + item.name)
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
export default {
  name: "SelectFieldToAdd",

  components: {},

  props: {
    perm: { type: Object, required: true },
    fields: { type: Array, required: true },
  },

  data() {
    return {
      data: [],
      fetchinData: false,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      items: [
        {
          value: "text",
          name: "Input",
        },
        {
          value: "textarea",
          name: "Textarea",
        },
        {
          value: "select",
          name: "Auswahl",
        },
        {
          value: "checkbox",
          name: "Checkbox",
        },
        {
          value: "radio",
          name: "Radio",
        },
      ],
    };
  },

  created: function () {
    this.getData();
  },

  methods: {
    async getData() {
      this.fetchinData = true;
      let response = await this.get(
        `/company/${this.companyID}/forms/fields/standard`
      );

      if (response.status === 200) {
        this.data = response.data.data;
      }

      this.fetchinData = false;
    },

    disabledByPermission(value) {
      if (value !== "1" && this.perm.master !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },

  computed: {
    selectableItems() {
      /* check if any of the standard-fields is already in use.
      Every standard-field can be used only once */

      let data = this.data;
      data.forEach((item) => {
        this.fields.forEach((field) => {
          if (field.name === item.name) {
            // already in fields
            item.disabled = true;
          }
        });
      });

      return data;
    },
  },

  mixins: [api, helpers],
};
</script>
