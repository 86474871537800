<template>
  <v-row class="caption mt-4" no-gutters>
    <v-col cols="12">
      <span v-if="data.fieldType === 'text'">
        <strong>{{ $t("plugins.forms.fieldType") }}:</strong>
        {{ data.fieldType }} ({{ $t("plugins.forms.inputType") }}:
        {{ data.inputType }})<br />
      </span>

      <span v-else>
        <strong>{{ $t("plugins.forms.fieldType") }}:</strong> {{ data.fieldType
        }}<br />
      </span>
    </v-col>

    <v-col cols="12">
      <strong> {{ $t("phrases.Name") }}: </strong>
      {{ data.name }}
    </v-col>

    <v-col cols="12">
      <strong>{{ $t("phrases.RequiredField") }}:</strong>
      {{ requiredText(data.isRequired) }}
    </v-col>

    <v-col cols="12" v-if="data.isStandard === '1'" class="mt-2">
      <v-chip x-small color="orange" text-color="white">
        {{ $t("plugins.forms.StandardField") }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PreviewInfo",

  components: {},

  props: {
    data: { type: Object, required: true },
  },

  data() {
    return {};
  },

  created: function () {},

  methods: {
    requiredText(value) {
      if (value === "1") {
        return this.$t("answers.yes");
      } else {
        return this.$t("answers.no");
      }
    },
  },
  computed: {},
};
</script>
