<template>
  <v-card>
    <Toolbar
      :data="fieldData"
      :perm="perm"
      :edit="edit"
      name="Input"
      @action="toolbarAction"
    />

    <template v-if="edit">
      <v-card-text v-if="edit">
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" v-if="fieldData.isStandard === '1'">
              <v-chip class="ma-2" color="orange" text-color="white">
                <v-icon left> mdi-information </v-icon>
                {{ $t("plugins.forms.StandardField") }}**
              </v-chip>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                outlined
                v-model="inputType"
                :rules="requiredRules"
                :items="inputTypes"
                :label="$t('phrases.FieldType')"
                :disabled="isDisabled()"
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="name"
                :label="$t('phrases.Name')"
                :rules="requiredRules"
                disabled
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="label"
                :label="$t('phrases.Label')"
                :rules="requiredRules"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="placeholderText"
                :label="$t('phrases.Placeholder')"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="hintText"
                :label="$t('phrases.Hint')"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                outlined
                v-model="colSize"
                :rules="requiredRules"
                :items="colSizes"
                :label="$t('phrases.ColumnWidth')"
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-checkbox
                v-model="isRequired"
                true-value="1"
                false-value="0"
                :label="$t('phrases.RequiredField')"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" class="caption">
              *) {{ $t("phrases.RequiredField") }}
            </v-col>

            <v-col cols="12" v-if="data.isStandard === '1'" class="caption">
              **) {{ $t("plugins.forms.StandardFieldInfo") }}
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions v-if="edit">
        <v-spacer />
        <v-btn color="grey" text @click="cancelEdit()">
          {{ $t("controles.Cancel") }}
        </v-btn>
        <v-btn
          v-if="valid"
          color="green"
          dark
          @click="safeData"
          :loading="fetchinData"
          :disabled="disabledByPermission(perm.write)"
          >{{ $t("controles.Safe") }}</v-btn
        >
        <v-btn v-else disabled>{{ $t("controles.Safe") }}</v-btn>
      </v-card-actions>
    </template>

    <template v-else>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2" class="font-weight-bold align-self-center">
            {{ fieldData.label }} <span v-if="fieldData.isRequired">*</span>
          </v-col>

          <v-col cols="12" md="10" class="align-self-center">
            <v-text-field
              :type="fieldData.inputType"
              :placeholder="placeholderText"
              solo
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <PreviewInfo :data="fieldData" />
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import Toolbar from "@/components/plugins/forms/field-components/assets/Toolbar";
import PreviewInfo from "@/components/plugins/forms/field-components/assets/PreviewInfo";
export default {
  name: "textType",

  components: {
    Toolbar,
    PreviewInfo,
  },

  data() {
    return {
      fieldData: this.data,
      valid: false,
      fetchinData: false,
      // fields to use
      name: this.data.name,
      label: this.data.label,
      placeholderText: this.data.placeholderText,
      hintText: this.data.hintText,
      isRequired: this.data.isRequired,
      inputType: this.data.inputType,
      inputOptions: this.data.inputOptions,
      isOnline: this.data.isOnline,
      colSize: this.data.colSize,
      // todo: reset to original
      edit: false,
      formUuid: this.$route.params.id,
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
      requiredRules: [(v) => !!v || "Field is required"],
      inputTypes: [
        "text",
        "number",
        "email",
        "password",
        "tel",
        "url",
        "color",
        "date",
        "file",
        "image",
        "hidden",
      ],
      colSizes: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    };
  },

  props: {
    data: { type: Object, required: true },
    perm: { type: Object, required: true },
  },

  methods: {
    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    isDisabled() {
      if (this.fieldData.isStandard === "1") {
        return true;
      } else {
        return false;
      }
    },

    toolbarAction(value) {
      if (value.action === "edit") {
        this.edit = true;
      } else if (value.action === "switchOnlineState") {
        this.isOnline = value.state;
        this.safeData();
      } else {
        this.$emit("action", value);
      }
    },

    cancelEdit() {
      this.edit = false;
      // reset to original
      // todo: hier müssen die mutierten werte wieder resettet werden
      this.fieldData = this.data;
      this.label = this.data.label;
      this.placeholderText = this.data.placeholderText;
      this.hintText = this.data.hintText;
      this.isRequired = this.data.isRequired;
      this.inputType = this.data.inputType;
      this.inputOptions = this.data.inputOptions;
      this.isOnline = this.data.isOnline;
      this.colSize = this.data.colSize;
      this.$emit("action", "cancel");
    },

    async safeData() {
      const params = new URLSearchParams();
      params.append("label", this.label);
      params.append("placeholderText", this.placeholderText);
      params.append("hintText", this.hintText);
      params.append("isRequired", this.isRequired);
      params.append("inputType", this.inputType);
      params.append("inputOptions", this.inputOptions);
      params.append("isOnline", this.isOnline);
      params.append("colSize", this.colSize);

      this.preloader = true;
      let response = await this.put(
        `/company/${this.companyID}/forms/${this.data.formUuid}/fields/${this.data.uuid}`,
        params
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemUpdatedMessage"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // todo: mutate original data
        this.fieldData = response.data.data[0];
        this.name = response.data.data[0].name;
        this.label = response.data.data[0].label;
        this.placeholderText = response.data.data[0].placeholderText;
        this.hintText = response.data.data[0].hintText;
        this.isRequired = response.data.data[0].isRequired;
        this.inputType = response.data.data[0].inputType;
        this.inputOptions = response.data.data[0].inputOptions;
        this.isOnline = response.data.data[0].isOnline;
        this.colSize = response.data.data[0].colSize;

        this.edit = false;
      }
    },
  },

  computed: {},

  mixins: [api, helpers],
};
</script>
