<template>
  <v-toolbar :color="stateColor()" flat dense dark>
    <v-switch
      class="mx-1 pr-0"
      v-model="data.isOnline"
      color="white"
      inset
      false-value="0"
      true-value="1"
      hide-details
      :disabled="disabledByPermission(perm.write)"
      @change="
        $emit('action', {
          action: 'switchOnlineState',
          state: data.isOnline,
          uuid: data.uuid,
        })
      "
    ></v-switch>

    <v-toolbar-title class="text-overline">
      {{ $t("plugins.frontend.Component") }}: {{ name }}
    </v-toolbar-title>
    <v-spacer />

    <v-btn
      v-if="!edit"
      fab
      dark
      elevation="0"
      x-small
      color="blue"
      class="white--text mx-1"
      :disabled="disabledByPermission(perm.write)"
      @click="
        $emit('action', {
          action: 'edit',
          uuid: data.uuid,
        })
      "
    >
      <v-icon dark> mdi-pencil </v-icon>
    </v-btn>

    <v-btn
      fab
      dark
      elevation="0"
      x-small
      color="red"
      class="white--text mx-1"
      :disabled="disabledByPermission(perm.delete)"
      @click="
        $emit('action', {
          action: 'delete',
          uuid: data.uuid,
        })
      "
    >
      <v-icon dark> mdi-trash-can </v-icon>
    </v-btn>

    <v-icon v-if="perm.write" class="draghandle"> mdi-drag </v-icon>
    <v-icon v-else disabled style="opacity: 0.5"> mdi-drag </v-icon>
  </v-toolbar>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
export default {
  name: "Toolbar",

  components: {},

  data() {
    return {
      companyID: store.getters["auth/session"].currentCompany.companyUuid,
    };
  },

  props: {
    data: { type: Object, required: true },
    perm: { type: Object, required: true },
    edit: { type: Boolean, required: true },
    name: { type: String, required: true },
  },

  created: function () {},

  methods: {
    stateColor() {
      if (this.data.isOnline === "1") {
        return "green";
      } else {
        return "grey";
      }
    },
    disabledByPermission(value) {
      if (value !== "1" && this.perm.master !== "1") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {},
  mixins: [api, helpers],
};
</script>
